// reducers/authReducer.js
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_RESET,

  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_RESET,

  SOCIAL_CONNECT_REQUEST,
  SOCIAL_CONNECT_SUCCESS,
  SOCIAL_CONNECT_FAIL,
  SOCIAL_CONNECT_RESET,

  SOCIAL_CALLBACK_REQUEST,
  SOCIAL_CALLBACK_SUCCESS,
  SOCIAL_CALLBACK_FAIL,
  SOCIAL_CALLBACK_RESET,

  OTP_SEND_REQUEST,
  OTP_SEND_SUCCESS,
  OTP_SEND_FAIL,
  OTP_SEND_RESET,

  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAIL,
  OTP_VERIFY_RESET,
} from 'src/store/auth/constants';


export const login = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { loading: true };

    case LOGIN_SUCCESS:
      return { loading: false, auth: action.payload };

    case LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case LOGIN_RESET:
      return {};

    default:
      return state;
  }
};

export const signup = (state = {}, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return { loading: true };

    case SIGNUP_SUCCESS:
      return { loading: false, auth: action.payload };

    case SIGNUP_FAIL:
      return { loading: false, error: action.payload };

    case SIGNUP_RESET:
      return {};

    default:
      return state;
  }
};

export const socialConnect = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_CONNECT_REQUEST:
      return { loading: true };

    case SOCIAL_CONNECT_SUCCESS:
      return { loading: false, redirect: action.payload };

    case SOCIAL_CONNECT_FAIL:
      return { loading: false, error: action.payload };

    case SOCIAL_CONNECT_RESET:
      return {};

    default:
      return state;
  }
};

export const socialConnectCallback = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_CALLBACK_REQUEST:
      return { loading: true };

    case SOCIAL_CALLBACK_SUCCESS:
      return { loading: false, auth: action.payload };

    case SOCIAL_CALLBACK_FAIL:
      return { loading: false, error: action.payload };

    case SOCIAL_CALLBACK_RESET:
      return {};

    default:
      return state;
  }
};


export const sendOtp = (state = {}, action) => {
  switch (action.type) {
    case OTP_SEND_REQUEST:
      return { loading: true };

    case OTP_SEND_SUCCESS:
      return { loading: false, success: true };

    case OTP_SEND_FAIL:
      return { loading: false, error: action.payload };

    case OTP_SEND_RESET:
      return {};

    default:
      return state;
  }
};


export const verifyOtp = (state = {}, action) => {
  switch (action.type) {
    case OTP_VERIFY_REQUEST:
      return { loading: true };

    case OTP_VERIFY_SUCCESS:
      return { loading: false, auth: action.payload };

    case OTP_VERIFY_FAIL:
      return { loading: false, error: action.payload };

    case OTP_VERIFY_RESET:
      return {};

    default:
      return state;
  }
};