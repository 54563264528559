// actions/actions.js
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,

  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,

  SOCIAL_CONNECT_REQUEST,
  SOCIAL_CONNECT_SUCCESS,
  SOCIAL_CONNECT_FAIL,

  SOCIAL_CALLBACK_REQUEST,
  SOCIAL_CALLBACK_SUCCESS,
  SOCIAL_CALLBACK_FAIL,

  OTP_SEND_REQUEST,
  OTP_SEND_SUCCESS,
  OTP_SEND_FAIL,

  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAIL
} from 'src/store/auth/constants';
import { AUTH_APIS } from 'src/apis/authApis';
import Cookies from 'js-cookie';
import {CONFIG} from "src/commons/config";

// Login
export const login = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_REQUEST
    });

    let response = await AUTH_APIS.authenticate(data);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    if(response?.access_token) {
      Cookies.set('access_token', response?.access_token, {domain: CONFIG.DOMAIN});
    }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response,
    });
  }

  catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};

// Register
export const signup = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SIGNUP_REQUEST
    });

    let response = await AUTH_APIS.register(data);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    if(response?.access_token) {
      Cookies.set('access_token', response?.access_token, {domain: CONFIG.DOMAIN});
    }
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: response,
    });
  }
  catch (error) {
    dispatch({
      type: SIGNUP_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};

// Social Connect
export const socialConnect = (provider, data) => async (dispatch) => {
  try {
    dispatch({
      type: SOCIAL_CONNECT_REQUEST
    });

    let response = await AUTH_APIS.socialConnect(provider, data);
    response = response.data;
    // action `${CONFIG.APP_BACKEND_URL}/api/v1/auth/socials/${provider}`

    if (response && response.error) {
      throw response;
    }

    dispatch({
      type: SOCIAL_CONNECT_SUCCESS,
      payload: response.redirect,
    });
  }
  catch (error) {
    dispatch({
      type: SOCIAL_CONNECT_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};


export const socialConnectCallback = (provider, data) => async (dispatch) => {
  try {
    dispatch({
      type: SOCIAL_CALLBACK_REQUEST
    });

    let response = await AUTH_APIS.socialConnectCallback(provider, data);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    if(response?.access_token) {
      Cookies.set('access_token', response?.access_token, {domain: CONFIG.DOMAIN});
    }
    dispatch({
      type: SOCIAL_CALLBACK_SUCCESS,
      payload: response,
    });
  }
  catch (error) {
    dispatch({
      type: SOCIAL_CALLBACK_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};

// Send OTP
export const sendOtp = (data) => async (dispatch) => {
  try {
    dispatch({ type: OTP_SEND_REQUEST });

    let response = await AUTH_APIS.sendOtp(data);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    dispatch({
      type: OTP_SEND_SUCCESS,
      payload: response,
    });
  }
  catch (error) {
    dispatch({
      type: OTP_SEND_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};

// Verify OTP
export const verifyOtp = (data) => async (dispatch) => {
  try {
    dispatch({
      type: OTP_VERIFY_REQUEST
    });

    let response = await AUTH_APIS.verifyOtp(data);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    if(response?.access_token) {
      Cookies.set('access_token', response?.access_token, {domain: CONFIG.DOMAIN});
    }
    dispatch({
      type: OTP_VERIFY_SUCCESS,
      payload: response,
    });
  }
  catch (error) {
    dispatch({
      type: OTP_VERIFY_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};
