import React from 'react';
import {Routes, Route} from "react-router-dom";
import SocialCallbackScreen from "src/screens/SocialCallbackScreen";
import LoginScreen from "src/screens/LoginScreen";
import SignupScreen from "src/screens/SignupScreen";
import LandingScreen from "src/screens/LandingScreen";
import TermsAndConditionsScreen from "src/screens/TermsAndConditionsScreen";
import PrivacyPolicyScreen from "src/screens/PrivacyPolicyScreen";

function Router(props) {
  return (
    <Routes>
      <Route path='' element={<LandingScreen />} />
      <Route path='/accounts/providers/:provider/callback' element={<SocialCallbackScreen />} />
      <Route path="/account/login" element={<LoginScreen />} />
      <Route path="/account/signup" element={<SignupScreen />} />
      <Route path="/policies/tnc" element={<TermsAndConditionsScreen />} />
      <Route path="/policies/privacy" element={<PrivacyPolicyScreen />} />
      <Route path="*" element={<LoginScreen />} />
    </Routes>
  );
}

export default Router;