import React from 'react';
import {cn} from "src/lib/utils";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Button from "src/components/Button";

function Header(props) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <header className={
      cn("sm:fixed sm:top-2 w-full mx-auto z-50", props.className)
    }>
      <div className='relative justify-center mx-auto md:w-[60%] sm:w-[80%] w-full z-50'>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-600 opacity-20 sm:rounded-full"></div>
        <div className={
          cn('relative w-full py-3 px-4 backdrop-blur-md sm:rounded-full flex items-center justify-between gap-x-6', props.className)
        }>
          {/* Logo */}
          <Link to="/" className="flex-shrink-0 px-4">
            <img className="h-8" src={'/media/images/logo/logo.svg'} alt="logo" />
          </Link>

          {/* Actions */}
          {
            !(location.pathname.includes('login') || location.pathname.includes('signup')) && (
              <div className="flex items-center gap-x-4">
                <Button className="text-sm font-medium sm:rounded-full" variant="ghost" onClick={() => navigate('/account/login')}>
                  Log In
                </Button>
                <Button className="text-sm font-medium sm:rounded-full" variant="default" onClick={() => navigate('/account/signup')}>
                  Sign Up
                </Button>
              </div>
            )
          }
        </div>
      </div>
    </header>
  );
}

export default Header;