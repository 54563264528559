import axios from "axios";
import {UTILS} from "src/commons/utils";

// axios.interceptors.request.use(function (request) {
//   const token = UTILS.getToken();
//   if(token) {
//     request.headers['Authorization'] = `Bearer ${token}`
//   }
//   return request;
// })

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error && error.response && error.response.data) {
    const e = error.response.data;
    console.log(window.location)
    if(e && e.errors && e.errors.code && e.errors.code === 'token_not_valid') {
      console.log(window.location)
      // window.location.replace(window.location.href + 'expired')
    }
  }
  return Promise.reject(error);
});

export const CONFIG = {
  /*
   * Local Configs
   */
  // DOMAIN: 'localhost',
  // AUTH_FRONTEND_URL: 'http://localhost:3000',
  // APP_FRONTEND_URL: 'http://localhost:3001/app',
  // APP_BACKEND_URL: 'http://localhost:8000',

  /*
   * Production Configs
   */
  DOMAIN: 'postpropel.com',
  AUTH_FRONTEND_URL: 'https://postpropel.com',
  APP_FRONTEND_URL: 'https://postpropel.com/app',
  APP_BACKEND_URL: 'https://sora.postpropel.com',

  SOCIAL_CONNECT_PROVIDERS: {
    LINKEDIN: 'oidc/linkedin',
  }
}
