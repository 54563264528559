import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {toast} from "src/components/ui/use-toast";
import {CircleCheck, TriangleAlert} from "lucide-react";
import {sendOtp, verifyOtp} from "src/store/auth/actions";
import {UTILS} from "src/commons/utils";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "src/components/ui/card";
import {InputOTP, InputOTPGroup, InputOTPSlot} from "src/components/ui/input-otp";
import {REGEXP_ONLY_DIGITS_AND_CHARS} from "input-otp";
import Button from "src/components/Button";
import {cn} from "src/lib/utils";

function OtpCard(props) {
  const { context, redirect } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [resendAttempts, setResendAttempts] = useState(3);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const { loading, auth, error } = useSelector(state => state.verifyOtp);
  const { error: resendError, success: resendSuccess, loading: resendLoading } = useSelector(state => state.sendOtp);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if(resendError) {
      toast({
        title: (
          <div className='flex flex-row gap-x-2'>
            <div className='flex flex-col'>
              <TriangleAlert className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Uh Oh! Could not send OTP!
            </div>
          </div>
        ),
        description: resendError.message,
        variant: 'destructive',
      });
    }
    else if(resendSuccess) {
      toast({
        title: (
          <div className='flex flex-row gap-x-2'>
            <div className='flex flex-col'>
              <CircleCheck className=''/>
            </div>
            <div className='flex flex-col my-auto'>
              Re-sent OTP!
            </div>
          </div>
        ),
        description: 'OTP was sent successfully! Please check your inbox!',
      });
    }
  }, [resendError, resendSuccess]);

  useEffect(() => {
    var redirectCallback = null;

    if(error) {
      toast({
        title: (
          <div className='flex flex-row gap-x-2'>
            <div className='flex flex-col'>
              <TriangleAlert className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Uh Oh! Could not send OTP!
            </div>
          </div>
        ),
        description: error.message,
        variant: 'destructive',
      });
    }
    else if(auth) {
      toast({
        title: (
          <div className='flex flex-row gap-x-2'>
            <div className='flex flex-col'>
              <CircleCheck className=''/>
            </div>
            <div className='flex flex-col my-auto'>
              Woohoo! Successfully Verified!
            </div>
          </div>
        ),
        description: 'Verification Successful! Redirecting',
      });

      redirectCallback = setTimeout(
        () => {
          if(redirect.startsWith("http")) {
            window.location.replace(redirect);
          } else {
            navigate(redirect)
          }
        },
        2000
      )
    }

    return () => {
      if(redirectCallback) clearTimeout(redirectCallback);
    }
  }, [error, auth]);


  const handleResend = () => {
    if (resendAttempts > 0) {
      setTimer(60);
      setResendAttempts(resendAttempts - 1);
      setIsResendDisabled(true);
      // Add your resend OTP logic here

      dispatch(sendOtp({
        'email': context.email,
      }))
    }
  };

  const onOtpHandler = (e) => {
    e.preventDefault();

    dispatch(verifyOtp({
      "email": context.email,
      "otp": otp,
    }));
  }

  return (
    <Card className="mx-auto max-w-lg">
      <CardHeader>
        <CardTitle className="text-2xl">OTP</CardTitle>
        <CardDescription>
          Enter the code that you received on your email.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form id='otpForm' className="grid gap-4" onSubmit={onOtpHandler}>
          <InputOTP
            maxLength={6}
            pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
            value={otp}
            onChange={(value) => setOtp(value)}
          >
            <InputOTPGroup className='flex flex-row mx-auto py-4'>
              <InputOTPSlot className='h-16 w-16 text-xl' index={0} />
              <InputOTPSlot className='h-16 w-16 text-xl' index={1} />
              <InputOTPSlot className='h-16 w-16 text-xl' index={2} />
              <InputOTPSlot className='h-16 w-16 text-xl' index={3} />
              <InputOTPSlot className='h-16 w-16 text-xl' index={4} />
              <InputOTPSlot className='h-16 w-16 text-xl' index={5} />
            </InputOTPGroup>
          </InputOTP>

          <Button
            form='otpForm'
            type="submit"
            className="w-[50%] mx-auto"
            disabled={resendLoading || otp.length < 6}
            loading={resendLoading || loading || auth}
          >
            Submit
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Did't receive otp?{" "}
          <span className={
            cn("underline", isResendDisabled ? "text-foreground/60" : "cursor-pointer")}
                onClick={() => {
                  if(!isResendDisabled) {
                    handleResend();
                  }}
                }>
            Resend
            {
              isResendDisabled ? (
                <span>({timer}s)</span>
              ) : (
                <span>({resendAttempts})</span>
              )
            }
          </span>
        </div>
      </CardContent>
    </Card>
  )
}

export default OtpCard;