import React from 'react';

function PrivacyPolicyScreen() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-[12vh]">
      <h1 className="relative text-4xl font-bold text-center p-8 m-4">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-600 opacity-10 z-40 rounded-3xl"></div>
        Privacy Policy
      </h1>

      <div className='flex flex-col gap-8 p-12'>
        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p>
            At PostPropel ("we," "us," "our") (unregistered), we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your personal data when you use our website and services ("Services"). By using our Services, you agree to the terms outlined in this Privacy Policy.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
          <p>We collect the following types of personal information:</p>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-4">
              <strong>Personal Data Provided by You:</strong>
              <ol className="list-disc list-inside ml-6">
                <li className="mb-2">
                  <strong>Account Information:</strong> Name, email address, and contact information when you create an account or communicate with us.
                </li>
                <li className="mb-2">
                  <strong>Payment Information:</strong> Details necessary to process transactions, including payment methods.
                </li>
                <li>
                  <strong>Social Media Information:</strong> Data from social media profiles, posts, etc (e.g., LinkedIn) when you connect your accounts to our Services.
                </li>
              </ol>
            </li>
            <li>
              <strong>Automatically Collected Data:</strong>
              <ol className="list-disc list-inside ml-6">
                <li className="mb-2">
                  <strong>Usage Data:</strong> IP address, browser type, operating system, and interactions with our website (e.g., pages visited, time spent on pages, posts made).
                </li>
                <li>
                  <strong>Cookies and Tracking Technologies:</strong> Data collected through cookies and similar technologies for functionality, performance, and analytics.
                </li>
              </ol>
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Legal Basis for Processing (GDPR)</h2>
          <p>For users in the European Union, we process your data based on the following legal grounds:</p>
          <ol className="list-disc list-inside ml-6">
            <li className="mb-2">
              <strong>Consent:</strong> When you provide explicit consent to process your data for specific purposes.
            </li>
            <li className="mb-2">
              <strong>Contract:</strong> When processing is necessary to fulfill a contract with you or take steps at your request before entering into such a contract.
            </li>
            <li className="mb-2">
              <strong>Legitimate Interests:</strong> When processing is necessary for our legitimate interests, provided these interests are not overridden by your data protection rights.
            </li>
            <li>
              <strong>Legal Obligation:</strong> When processing is necessary to comply with legal obligations.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
          <p>We use your personal information for the following purposes:</p>
          <ul className="list-disc list-inside ml-6">
            <li className="mb-2">
              <strong>To Provide Services:</strong> Manage your account, facilitate content creation, process payments, and communicate with you.
            </li>
            <li className="mb-2">
              <strong>To Improve Services:</strong> Analyze usage patterns, enhance our website, and develop new features.
            </li>
            <li>
              <strong>To Comply with Legal Obligations:</strong> Meet legal requirements and respond to lawful requests from authorities.
            </li>
          </ul>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Sharing Your Information</h2>
          <p>We do not sell or rent your personal information to third parties. However, we may share your data under the following circumstances:</p>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <strong>Service Providers:</strong> We may share information with third-party vendors who provide services such as payment processing, analytics, or cloud storage. These include:
              <ul className="list-disc list-inside ml-6">
                <li className="mb-2">
                  <a href="https://www.paddle.com/legal/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Payment Processor
                  </a>
                </li>
                <li className="mb-2">
                  <a href="https://analytics.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Analytics Provider
                  </a>
                </li>
                <li>
                  <a href="https://privacy.microsoft.com/en-gb/privacystatement" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Cloud Storage Provider
                  </a>
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <strong>Legal Compliance:</strong> We may disclose information if required by law or in response to a valid legal request from authorities.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a business transition (e.g., merger or acquisition), your data may be transferred to the new owner.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">International Data Transfers</h2>
          <p>As PostPropel provides services globally, your information may be transferred to and processed in countries outside your own. We ensure that such transfers are conducted with appropriate safeguards, including:</p>
          <ul className="list-disc list-inside ml-6">
            <li className="mb-2">
              <strong>Standard Contractual Clauses (SCCs):</strong> Implemented to provide adequate protection for data transfers outside the European Economic Area (EEA).
            </li>
            <li>
              <strong>Binding Corporate Rules (BCRs):</strong> Used where applicable, to ensure compliance with international data protection standards.
            </li>
          </ul>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Data Retention</h2>
          <p>We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, including:</p>
          <ul className="list-disc list-inside ml-6">
            <li className="mb-2">
              <strong>Account Data:</strong> For as long as your account is active.
            </li>
            <li className="mb-2">
              <strong>Transaction Data:</strong> For the duration necessary to process payments and comply with financial regulations.
            </li>
            <li className="mb-2">
              <strong>Usage Data:</strong> For up to [X months/years] to improve our Services.
            </li>
            <li>
              <strong>Marketing Data:</strong> For as long as you have consented to receive communications.
            </li>
          </ul>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
          <p>You have the following rights concerning your personal data:</p>
          <ul className="list-disc list-inside ml-6">
            <li className="mb-2">
              <strong>Access and Correction:</strong> Request access to your data and ask for corrections of inaccuracies.
            </li>
            <li className="mb-2">
              <strong>Deletion:</strong> Request deletion of your data, subject to legal obligations.
            </li>
            <li className="mb-2">
              <strong>Objection:</strong> Object to the processing of your data, particularly for direct marketing purposes.
            </li>
            <li>
              <strong>Data Portability:</strong> Request to receive your data in a structured, commonly used format.
            </li>
          </ul>
          <p className="mt-2">To exercise these rights, please contact us at <a href="mailto:support@postpropel.com" className="text-blue-500 hover:underline">support@postpropel.com</a>. We aim to respond to all requests within [30 days], in accordance with applicable laws.</p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">California Privacy Rights (CCPA)</h2>
          <p>If you are a California resident, you have the following rights under the California Consumer Privacy Act (CCPA):</p>
          <ul className="list-disc list-inside ml-6">
            <li className="mb-2">
              <strong>Right to Know:</strong> Request information about the categories and specific pieces of personal data we collect, use, and share.
            </li>
            <li className="mb-2">
              <strong>Right to Delete:</strong> Request deletion of your personal data.
            </li>
            <li>
              <strong>Right to Opt-Out:</strong> Opt-out of the sale of your personal data. (Note: We do not sell your personal data.)
            </li>
          </ul>
          <p className="mt-2">To exercise these rights, please contact us at <a href="mailto:support@postpropel.com" className="text-blue-500 hover:underline">support@postpropel.com</a>.</p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Children's Privacy (COPPA)</h2>
          <p>Our Services are not intended for individuals under 13 years of age. We do not knowingly collect personal information from children. If we become aware that we have collected data from a child under 13 without parental consent, we will promptly delete the information and notify the child’s parents.</p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar technologies to enhance your experience on our website. You can manage cookie preferences through your browser settings. However, disabling cookies may affect certain features of our Services. Our policy on cookies is detailed in our{' '}
            <a href="https://www.notion.so/Privacy-Policy-4e5b6504f4fb41539fa1e29416279c75?pvs=21" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
              Cookie Policy
            </a>
            .
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
          <p>We implement reasonable and appropriate security measures to protect your personal data, including:</p>
          <ul className="list-disc list-inside ml-6">
            <li className="mb-2">
              <strong>Encryption:</strong> Selected data encryption during transmission and storage.
            </li>
            <li className="mb-2">
              <strong>Access Controls:</strong> Restricted access to personal data within our organization, secured by access protocols.
            </li>
            <li>
              <strong>Regular Security Audits:</strong> Periodic audits to assess and improve our security practices.
            </li>
          </ul>
          <p className="mt-2">Despite these measures, no method of transmission or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Data Breach Notification</h2>
          <p>In the event of a data breach that may compromise your personal data, we will notify you without undue delay. We will also notify relevant authorities as required by applicable laws.</p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Do Not Track Signals</h2>
          <p>We do not respond to "Do Not Track" signals from web browsers. You can manage your tracking preferences through your browser settings.</p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of material changes by posting the new policy on this page and updating the "Effective Date" at the top. Continued use of our Services after such changes constitutes acceptance of the revised policy.</p>
        </section>

        <section className="flex flex-col mt-4">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p>For questions or concerns about this Privacy Policy or our handling of your personal data, please contact us at:</p>
          <p className="font-bold">PostPropel</p>
          <p>
            Email: <a href="mailto:support@postpropel.com" className="text-blue-500 hover:underline">support@postpropel.com</a>
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicyScreen;