import {
  PLAN_DELETE_FAIL,
  PLAN_DELETE_REQUEST,
  PLAN_DELETE_SUCCESS,

  PLAN_LIST_FAIL,
  PLAN_LIST_REQUEST,
  PLAN_LIST_SUCCESS,

  PLAN_RETRIEVE_FAIL,
  PLAN_RETRIEVE_REQUEST,
  PLAN_RETRIEVE_SUCCESS,

  PLAN_UPDATE_FAIL,
  PLAN_UPDATE_REQUEST,
  PLAN_UPDATE_SUCCESS
} from "src/store/plan/constants";
import {PLAN_APIS} from "src/apis/planApis";


export const list = (params = {}) => async (dispatch) => {
  try {
    dispatch({ type: PLAN_LIST_REQUEST });

    const response = await PLAN_APIS.list(params);

    dispatch({
      type: PLAN_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: PLAN_LIST_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.message,
      },
    });
  }
};

// Retrieve
export const retrieve = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLAN_RETRIEVE_REQUEST });

    const response = await PLAN_APIS.retrieve(id);

    dispatch({
      type: PLAN_RETRIEVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: PLAN_RETRIEVE_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.message,
      },
    });
  }
};

// Update
export const update = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: PLAN_UPDATE_REQUEST });

    const response = await PLAN_APIS.partialUpdate(id, data);

    dispatch({
      type: PLAN_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: PLAN_UPDATE_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.message,
      },
    });
  }
};


// Delete
export const deletePlan = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLAN_DELETE_REQUEST });

    await PLAN_APIS.delete(id);

    dispatch({
      type: PLAN_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PLAN_DELETE_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.message,
      },
    });
  }
};