import {UTILS} from "src/commons/utils";
import {CONFIG} from "src/commons/config";

export const PLAN_APIS = {
  list: (params = {}, config = {}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: '/api/v1/billings/plans/',
      method: 'GET',
      params: params,
      ...config
    });
  },

  retrieve: (id, config = {}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/billings/plans/${id}/`,
      method: 'GET',
      ...config
    });
  },

  update: (id, data, config = {}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/billings/plans/${id}/`,
      method: 'PUT',
      data: data,
      ...config
    });
  },

  partialUpdate: (id, data, config = {}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/billings/plans/${id}/`,
      method: 'PATCH',
      data: data,
      ...config
    });
  },

  delete: (id, config = {}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/billings/plans/${id}/`,
      method: 'DELETE',
      ...config
    });
  },
};