import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import { combineReducers } from 'redux';
import {composeWithDevTools} from "@redux-devtools/extension";

import {
  login,
  signup,
  sendOtp,
  verifyOtp,
  socialConnect,
  socialConnectCallback,
} from 'src/store/auth/reducers';
import {
  list as listPlans,
  retrieve as retrievePlan,
} from 'src/store/plan/reducers';
import {
  create as createContactUs
} from 'src/store/contact/reducers'


const reducers = combineReducers({
  login,
  signup,
  sendOtp,
  verifyOtp,
  socialConnect,
  socialConnectCallback,

  listPlans,
  retrievePlan,

  createContactUs,
})

const initialState = {

}

const middlewares = [thunk]

const store = createStore(reducers, initialState,
  composeWithDevTools(applyMiddleware(...middlewares)))

export default store;