import React, {useEffect, useState} from 'react';
import {Form, Link, useNavigate, useSearchParams} from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "src/components/ui/card";
import Input from "src/components/Input";
import Button from "src/components/Button";
import {cn} from "src/lib/utils";
import {toast, useToast} from "src/components/ui/use-toast";
import {CircleCheck, TriangleAlert} from "lucide-react";
import {UTILS} from "src/commons/utils";
import {useDispatch, useSelector} from "react-redux";
import {login, socialConnect} from "src/store/auth/actions";
import {CONFIG} from "src/commons/config";
import OtpCard from "src/components/OtpCard";
import Loading from "src/components/Loading";
import {AuroraBackground} from "src/components/ui/aurora-background";


function LoginCard(props) {
  const { toast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setContext } = props;

  const { loading: emailLoginLoading, auth: emailLoginAuth, error: emailLoginError } = useSelector(state => state.login);
  const { loading: socialConnectLoading, redirect, error: socialConnectError } = useSelector(state => state.socialConnect);

  useEffect(() => {
    let redirectTimeout;

    if(emailLoginError || socialConnectError) {
      toast({
        title: (
          <div className='flex flex-row gap-x-1 justify-start'>
            <div className='flex flex-col'>
              <TriangleAlert className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Uh Oh! Cannot Authenticate!
            </div>
          </div>
        ),
        description: emailLoginError ? emailLoginError.message : socialConnectError.message,
        variant: 'destructive',
      });

      if(emailLoginError && emailLoginError.errors?.email && emailLoginError.errors.email.includes('unverified')) {
        navigate('/account/login?step=verify-otp');
      }
    } else if(emailLoginAuth) {
      toast({
        title: (
          <div className='flex flex-row gap-x-1 justify-start'>
            <div className='flex flex-col'>
              <CircleCheck className=''/>
            </div>
            <div className='flex flex-col my-auto'>
              Woohoo! Successfully logged in!
            </div>
          </div>
        ),
        description: 'Login Successful. Redirecting.',
      });

      redirectTimeout = setTimeout(() => window.location.replace(CONFIG.APP_FRONTEND_URL), 2000)
    } else if(redirect) {
      window.location.replace(redirect);
    }

    return () => {
      clearTimeout(redirectTimeout);
    }
  }, [emailLoginError, socialConnectError, emailLoginAuth, redirect]);


  const onEmailLoginHandler = (e) => {
    e.preventDefault();

    let data = UTILS.buildJsonFromForm(e.target);
    setContext(data);
    dispatch(login(data));
  }

  const onSocialConnectHandler = (e, provider) => {
    e.preventDefault();

    const provider_id = provider.includes('/') ? provider.split('/').slice(-1)[0] : provider;
    const data = {
      provider: provider_id,
      process: 'login',
      callback_url: `${CONFIG.AUTH_FRONTEND_URL}/accounts/providers/${provider_id}/callback`
    }

    dispatch(socialConnect(provider, data));
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>
          Enter your email below to login to your account
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form id='loginForm' className="grid gap-4" onSubmit={onEmailLoginHandler}>
          <div className="grid gap-2">
            <Input
              id="email"
              type="email"
              label="Email"
              placeholder="m@example.com"
              required
            />
          </div>
          <div className="grid gap-2">
            <Input
              id="password"
              type="password"
              label="Password"
              placeholder="********"
              required />
            <Link to="#" className="inline-block text-sm underline">
              Forgot your password?
            </Link>
          </div>
          <Button
            form='loginForm'
            type="submit"
            className="w-full"
            disabled={emailLoginLoading || emailLoginAuth || socialConnectLoading || redirect}
            loading={emailLoginLoading || emailLoginAuth}
          >
            Login
          </Button>
          <Button
            type='button'
            variant="outline"
            className="w-full"
            disabled={emailLoginLoading || emailLoginAuth || socialConnectLoading || redirect}
            loading={socialConnectLoading || redirect}
            onClick={(e) => onSocialConnectHandler(e, CONFIG.SOCIAL_CONNECT_PROVIDERS.LINKEDIN)}
          >
            Login with LinkedIn
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Don&apos;t have an account?{" "}
          <Link to="/account/signup" className="underline">
            Sign up
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}

function LoginScreen(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [user, setUser] = useState();

  useEffect(() => {
    if(searchParams.get('step') === 'verify-otp') {
      if(!user) navigate('/account/login');
    }
  }, [searchParams, user]);

  return (
    <div className={(
      cn('h-screen w-full m-auto sm:py-[21vh] py-[11vh] sm:px-0 px-4 bg-muted/40')
    )}>
      {
        (searchParams.get('step') === 'verify-otp' && user) ? (
          <OtpCard context={user} navigate={''} />
        ) : (
          <LoginCard setContext={context => setUser(context)} />
        )
      }
    </div>
  );
}

export default LoginScreen;