import {
  CONTACT_US_CREATE_FAIL,
  CONTACT_US_CREATE_REQUEST,
  CONTACT_US_CREATE_SUCCESS,
  CONTACT_US_CREATE_RESET,
} from "src/store/contact/constants";

export const create = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_US_CREATE_REQUEST:
      return { loading: true };

    case CONTACT_US_CREATE_SUCCESS:
      return { loading: false, contactUs: action.payload };

    case CONTACT_US_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case CONTACT_US_CREATE_RESET:
      return {};

    default:
      return state;
  }
};