

export const DATA = {

  social: {
    linkedin: {
      'name': 'Jon Doe',
      'picture': '/media/images/illustrations/profile-pic-2-sm.webp',
      'bio': 'Software Engineer @ Cognato AI'
    }
  },

  plans: {
    page: 0,
    pages: 1,
    next: null,
    prev: null,
    results: [
      {
        "name": "Trial",
        "price": "0.00",
        "features": [
          "Generate 250 words for 7 days",
          "Generate post content",
          "Post directly on LinkedIn",
          "Add up to 1 writing style"
        ],
        "duration": 7,
        "description": "Get started with our content generation platform",
        "credits": 250,
        "personas": 1
      },
      {
        "name": "Basic",
        "price": "9.99",
        "features": [
          "Generate 1000 words/month",
          "Generate post content",
          "Post directly on LinkedIn",
          "Add up to 1 writing style",
          "$0.10 per 100 words beyond the included limit"
        ],
        "description": "Ideal for occasional creators who need basic content generation capabilities.",
        "duration": 30,
        "credits": 1000,
        "personas": 1
      },
      {
        "name": "Pro",
        "price": "19.99",
        "features": [
          "Generate 2000 words/month",
          "Generate post content",
          "Post directly on LinkedIn",
          "Add up to 2 writing styles",
          "$0.08 per 100 words beyond the included limit"
        ],
        "description": "Great for creators who require more content generation and multiple writing styles.",
        "duration": 30,
        "credits": 2000,
        "personas": 2
      },
      {
        "name": "Premium",
        "price": "49.99",
        "features": [
          "Generate 5000 words/month",
          "Generate post content",
          "Post directly on LinkedIn",
          "Add up to 5 writing styles",
          "$0.05 per 100 words beyond the included limit"
        ],
        "description": "Best for creators needing extensive content generation and multiple writing styles.",
        "duration": 30,
        "credits": 5000,
        "personas": 5
      },
    ]
  },

  customPlan: {
    "id": "custom-83409nrx9x",
    "name": "Custom",
    "price": "0.0000",
    "type": "custom",
    "features": [
      "Custom generation limits",
      "Custom posting limits",
      "Custom style limits",
    ],
    "description": "Tailored solutions for enterprise or specific requirements.",
    "credits": "Custom",
    "personas": "Custom"
  },
}