import React, {useEffect} from 'react';
import {cn} from "src/lib/utils";
import Loading from "src/components/Loading";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CircleCheck, TriangleAlert} from "lucide-react";
import {toast} from "src/components/ui/use-toast";
import {UTILS} from "src/commons/utils";
import {socialConnectCallback} from "src/store/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import {CONFIG} from "src/commons/config";

function SocialCallbackScreen(props) {
  const {provider} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  console.log(provider);

  const { auth, error } = useSelector(state => state.socialConnectCallback);

  useEffect(() => {
    let redirectTimeout;

    if(error) {
      toast({
        title: (
          <div className='flex flex-row gap-x-2'>
            <div className='flex flex-col'>
              <TriangleAlert className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Uh Oh! Cannot Authenticate!
            </div>
          </div>
        ),
        description: error.message,
        variant: 'destructive',
      });

      redirectTimeout = setTimeout(() => navigate('/account/login'), 2000)
    } else if(auth) {
      toast({
        title: (
          <div className='flex flex-row gap-x-2'>
            <div className='flex flex-col'>
              <CircleCheck className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Woohoo! Successfully logged in!
            </div>
          </div>
        ),
        description: 'Login Successful. Please wait while we redirect.',
        // variant: 'destructive',
      });

      redirectTimeout = setTimeout(() => window.location.replace(CONFIG.APP_FRONTEND_URL), 2000)
    }

    return () => clearTimeout(redirectTimeout);
  }, [auth, error]);


  useEffect(() => {
  if(searchParams.get('error')) {
   toast({
     title: (
       <div className='flex flex-row gap-x-2'>
         <div className='flex flex-col'>
           <TriangleAlert className='' />
         </div>
         <div className='flex flex-col my-auto'>
           {UTILS.toTitleCase(searchParams.get('error'))}
        </div>
       </div>
     ),
     description: null,
     variant: 'destructive',
   });
  }
  else if(searchParams.get('code')) {
   dispatch(socialConnectCallback(
     CONFIG.SOCIAL_CONNECT_PROVIDERS[provider.toUpperCase()], {
       code: searchParams.get('code'),
       state: searchParams.get('state'),
       callback_url: `${CONFIG.AUTH_FRONTEND_URL}/accounts/providers/${provider}/callback`,
     }));
   }
  }, [searchParams]);

  return (
    <div className={
      cn('h-screen w-screen items-center justify-center')
    }>
      <Loading loader={{
        className: 'h-[5vh] w-[5vh]'
      }} />
    </div>
  );
}

export default SocialCallbackScreen;