import React, {Suspense, useEffect, useState} from 'react';
import Button from "src/components/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "src/components/ui/card";
import {Badge} from "src/components/ui/badge";

import { motion } from "framer-motion";
import {ChartNoAxesCombined, Linkedin, PencilRuler, Send, StickyNote, Check} from "lucide-react";
import LinkedinPost from "src/components/LinkedinPost";
import {Separator} from "src/components/ui/separator";
import {DATA} from "src/commons/data";
import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import {useDispatch, useSelector} from "react-redux";
import {list as listPlans} from "src/store/plan/actions";
import { create as createContact } from "src/store/contact/actions";
import {UTILS} from "src/commons/utils";
import {useToast} from "src/components/ui/use-toast";
import {useNavigate} from "react-router-dom";
import {CONTACT_US_CREATE_RESET} from "src/store/contact/constants";
import {cn} from "src/lib/utils";
import {WavyBackground} from "src/components/ui/wavy-background";


function ContactUs(props) {
  const { toast } = useToast();

  const dispatch = useDispatch();

  const { error, contactUs, loading } = useSelector(state => state.createContactUs);

  useEffect(() => {
    let successTimeout = null;

    if(error) {
      toast(
        UTILS.getToastPayload(
          "error",
          "Oops! Something went wrong!",
          error.description
        )
      )
    } else if(contactUs) {
      toast(
        UTILS.getToastPayload(
          "success",
          "Aye Aye Captain!",
          "Your message was well received! We will get back to you ASAP!"
        )
      );

      // FIXME: Find a better way to handle this
      document.getElementById('contactUsForm').reset();

      successTimeout = setTimeout(() => {
        dispatch({
          type: CONTACT_US_CREATE_RESET
        })
      }, 2000)
    }

    return () => {
      if(successTimeout) clearTimeout(successTimeout);
    }
  }, [error, contactUs]);


  const handleSubmit = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    dispatch(createContact(data));
  };

  return (
    <section className={
      cn(
        "bg-white py-28 px-4 sm:px-6 lg:px-8",
        props.className
      )
    } id="contact">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-indigo-600 sm:text-4xl">
            Contact Us
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Have questions or feedback? We'd love to hear from you!
          </p>
        </div>

        <Card className="mt-12 mx-auto md:max-w-2xl bg-muted/80">
          <CardHeader>
            <CardTitle>Get in Touch</CardTitle>
            <CardDescription>
              Fill out the form below and we'll get back to you shortly.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <form id='contactUsForm' onSubmit={handleSubmit} className="space-y-2">
              <div>
                <Input
                  id="name"
                  type="text"
                  label="Name"
                  placeholder='Jon Doe'
                  required
                />
              </div>
              <div>
                <Input
                  id="email"
                  type="email"
                  label='Email'
                  placeholder='jon.doe@email.com'
                  required
                />
              </div>
              <div>
                <Textarea
                  id="message"
                  rows={4}
                  label='Message'
                  placeholder="This product is awesome!"
                  required
                />
              </div>
              <Button form='contactUsForm' type="submit" className="mt-12 w-full" loading={loading} disabled={loading}>
                Send Message
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};


const defaultPlans = [
  {
    "id": "32fcbdb8-85e1-4caf-9adc-9a97918d1532",
    "name": "Trial",
    "price": "0.0000",
    "offer_price": "0.0000",
    "credits": "100.0000",
    "duration": null,
    "features": [
      "Generate 100 words for 7 days",
      "Generate post content",
      "Post directly on LinkedIn",
      "Add up to 1 writing style",
      "Post up to 2 posts/day"
    ],
    "description": "Get started with our content generation platform",
    "type": "free"
  },
  {
    "id": "17c2a0a1-17e3-423a-bc4d-8add89b9d12d",
    "name": "Basic",
    "price": "9.9900",
    "offer_price": "4.9900",
    "credits": "1000.0000",
    "duration": 30,
    "features": [
      "Generate 1000 words/month",
      "Post up to 10 posts/day",
      "Generate post content",
      "Post directly on LinkedIn",
      "Add up to 1 writing style"
    ],
    "description": "Ideal for occasional creators who need basic content generation capabilities.",
    "type": "paid"
  },
  {
    "id": "d6e940de-da92-4aee-acff-845206ea2956",
    "name": "Pro",
    "price": "19.9900",
    "offer_price": "9.9900",
    "credits": "2000.0000",
    "duration": 30,
    "features": [
      "Generate 2000 words/month",
      "Post up to 20 posts/day",
      "Generate post content",
      "Post directly on LinkedIn",
      "Add up to 2 writing styles"
    ],
    "description": "Great for creators who require more content generation and multiple writing styles.",
    "type": "paid"
  }
];

function Pricing(props) {
  const { customPlan } = DATA;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [plans, setPlans] = useState(defaultPlans);

  const { error, plans: responsePlans } = useSelector(state => state.listPlans);

  useEffect(() => {
    if(!error && !responsePlans) {
      dispatch(listPlans());
    } else if(responsePlans) {
      setPlans(responsePlans.results);
    }
  }, [error, responsePlans]);


  return (
    <section className={
      cn(
        "py-28 px-4 sm:px-6 lg:px-8",
        props.className
      )
    } id="pricing">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Choose the <span className='text-indigo-600'>Right Plan for You</span>
        </h2>
        <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
          Flexible plans to suit your needs and budget.
        </p>

        <div className="mt-12 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {[...plans, customPlan].map((plan) => (
            <Card key={plan.id} className="border border-foreground/10 rounded-lg justify-between">

              <CardHeader className="sm:h-[23vh] h-[23vh]">
                <CardTitle className="flex flex-row text-2xl font-bold text-foreground">{plan.name}</CardTitle>
                <div className="flex flex-row items-baseline">
                  <span className="flex flex-col text-4xl font-bold text-foreground">
                    {(plan.type === "paid" && plan.price !== plan.offer_price) ? (
                      <span className='flex flex-row'>
                      <span className="text-4xl font-bold text-foreground/50 line-through mr-2">
                        ${parseFloat(plan.price).toFixed(2)}
                      </span>
                        <span className="text-4xl font-bold text-indigo-600">
                        ${parseFloat(plan.offer_price).toFixed(2)}
                      </span>
                      </span>
                    ) : (plan.type === "paid") ? (
                      <span className="text-4xl font-bold line-through mr-2">
                        ${parseFloat(plan.price).toFixed(2)}
                      </span>
                    ) : (
                      <span className="text-4xl font-bold text-gray-900">
                        {plan.type === "custom" ? "Custom" : "Free"}
                      </span>
                    )}
                  </span>
                  {plan.type === "paid" && (
                    <span className="flex flex-col text-foreground/50">/month</span>
                  )}
                </div>
                <CardDescription className='flex flex-row text-start'>{plan.description}</CardDescription>
              </CardHeader>

              <CardContent>
                {
                  plan.type === "paid" ? (
                    <Button className="w-full mb-4" variant="default"
                            onClick={() => navigate("/account/signup")}
                    >
                      Start Free & Upgrade
                    </Button>
                  ) : plan.type === "custom" ? (
                    <Button className="w-full mb-4" variant="default">
                      Contact Us
                    </Button>
                  ) : (
                    <Button className="w-full mb-4" variant="default"
                            onClick={() => navigate("/account/signup")}
                    >
                      Get Started
                    </Button>
                  )
                }

                <Separator className='mt-2 mb-4' />

                <ul className="text-gray-700 space-y-4">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center space-x-3">
                      <Check className="w-5 h-5 text-green-500" /> {/* Check Icon */}
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};



const steps = [
  {
    step: 1,
    title: 'Connect Your LinkedIn Account',
    description: 'Link your LinkedIn account to seamlessly integrate with our platform.',
    icon: (
      <Linkedin className='h-8 w-8' />
    )
  },
  {
    step: 2,
    title: 'Upload Writing Samples for Analysis',
    description: 'Upload samples of your existing writing to help us tailor the AI’s content to match your unique style.',
    icon: (
      <ChartNoAxesCombined className='h-8 w-8' />
    ),
  },
  {
    step: 3,
    title: 'Create Content',
    description: 'Start creating content by providing either a rough draft or a simple outline and ideas. Set parameters like tone and purpose.',
    icon: (
      <StickyNote className='h-8 w-8' />
    ),
  },
  {
    step: 4,
    title: 'Review and Edit',
    description: 'Preview and edit the AI-generated content to ensure it meets your needs before posting.',
    icon: (
      <PencilRuler className='h-8 w-8' />
    ),
  },
  {
    step: 5,
    title: 'Post Content',
    description: 'Choose to post immediately your content to go live.',
    icon: (
      <Send className='h-8 w-8' />
    ),
  },
];

function HowItWorks(props) {
  return (
    <section className={
      cn(
        "bg-white py-28 px-4 sm:px-6 lg:px-8",
        props.className,
      )
    } id="how-it-works">
      <div className="max-w-7xl mx-auto text-center space-y-16">
        <div className='flex flex-col gap-y-4'>
          <h2 className="text-3xl font-extrabold tracking-tight text-foreground sm:text-4xl">
            How It <span className='text-indigo-600'>Works</span>
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Get started in minutes and amplify your LinkedIn presence effortlessly.
          </p>
        </div>

        <div className='flex flex-row space-x-14'>
          <div className="flex flex-col space-y-6"> {/* Increased vertical spacing */}
          {steps.map((step) => (
            <div key={step.step} className="flex bg-muted/60 p-6 rounded-lg flex-col items-center md:flex-row md:space-x-8">
              {/* Switched to flexbox for better control */}

              <div className="relative p-6 flex items-center justify-center bg-indigo-100 rounded-full mb-4 md:mb-0">
                {/* Circle styling */}
                <span className="text-indigo-600 font-bold">{step.icon}</span>
              </div>

              <div className="text-left"> {/* Content aligned to the left */}
                <h3 className="text-xl font-semibold text-gray-900">{step.title}</h3>
                <p className="mt-2 text-gray-600">{step.description}</p>
              </div>

            </div>
          ))}
        </div>
          <div className="md:flex hidden flex-col md:w-[60%] w-0">
            <LinkedinPost />
          </div>
        </div>
      </div>
    </section>
  );
}


const features = [
  {
    title: 'AI-Powered Content Creation',
    description: 'Automatically generate high-quality LinkedIn posts using AI. Simply provide an outline or rough draft, and let our AI handle the rest.',
    image: '/media/images/illustrations/using-device.svg', // Replace with your actual image paths
  },
  {
    title: 'Easy LinkedIn Integration',
    description: 'Seamlessly connect your LinkedIn account for direct posting and content management without leaving the platform.',
    image: '/media/images/illustrations/drinking.svg',
  },
  {
    title: 'Personalized Content Styling',
    description: 'Upload samples of your writing to tailor the AI’s output to match your unique style and voice.',
    image: '/media/images/illustrations/sitting-with-glasses.svg',
  },
  {
    title: 'Customizable Content Parameters',
    description: 'Define the tone, purpose, and other parameters for each post to ensure it meets your specific needs and goals.',
    image: '/media/images/illustrations/whatever.svg',
  },
  {
    title: 'Preview and Edit Before Posting',
    description: 'Review and make adjustments to your AI-generated content before it goes live on LinkedIn.',
    image: '/media/images/illustrations/using-laptop.svg',
  },
  {
    title: (
      <span className='text-indigo-600'>More coming soon...</span>
    ),
    description: 'We are working on adding more features. Drop your feedbacks and requirements',
    image: '/media/images/illustrations/sitting-smiling.svg',
  },
];

const tags = [
  'AI-Powered',
  "LinkedIn Posts",
  "Preview & Edit",
  "Content Creation",
  "Writing Style Analysis",
]

function Features(props) {
  return (
    <section className={
      cn(
        "py-28 px-4 sm:px-6 lg:px-8 w-full mx-auto",
        props.className
      )
    }>
      <div className="max-w-7xl mx-auto text-center items-cente space-y-12">
        <div className='flex flex-col w-full gap-y-4'>
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
            Many <span className='text-indigo-600'>Great Features</span>
          </h2>
          <p className="text-lg text-muted-foreground">
            Boost your LinkedIn presence with our powerful AI-driven tools.
          </p>

          <div className='w-[60%] flex flex-wrap gap-2 mx-auto items-center'>
            {
              tags.map(tag => (
                <Badge variant='ghost' className='flex py-1 px-4 bg-muted-foreground/20 text-sm font-medium mx-auto'>{tag}</Badge>
              ))
            }
          </div>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <Card>
              <CardHeader className="pb-4">
                <CardTitle className="text-lg font-semibold text-foreground">{feature.title}</CardTitle>
                <CardDescription className="text-gray-600 mt-2">{feature.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex justify-center"> {/* Image at the bottom */}
                  <img loading='lazy' src={feature.image} alt={feature.title} className="h-52 w-auto" />
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

function HeroSubsection(props) {
  return (
    <section className={
      cn(
        "relative py-28 md:px-10 px-8 z-10",
        props.className
      )
    }>
      <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-600 opacity-10 z-40"></div>
      <div className="relative max-w-5xl mx-auto text-center">
        <h2
          className={cn(
            "text-4xl sm:text-5xl font-extrabold",
            // "bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-500"
            "text-foreground/90"
          )}
        >
          Struggling to Write Engaging Content?
        </h2>
        <p className="mt-4 text-lg sm:text-xl text-muted-foreground max-w-2xl mx-auto">
          Don’t worry! Borrow the writing style of top performers (with their consent) and generate compelling posts that match your brand effortlessly!
        </p>
      </div>
    </section>
  );
};

function Hero(props) {
  const navigate = useNavigate();

  return (
    <WavyBackground
      className='w-full'
      containerClassName='w-full'
      backgroundFill="white"
      blur={15}
      waveOpacity={0.5}
      colors={[
        "#67C7FF",
        "#A4B8FF",
        "#D6A5FF",
        "#F1B3FF",
        "#4DE0FF"
    ]} >
      <section className={
        cn(
          "w-full overflow-hidden sm:mt-[3vh] sm:py-[28vh] py-[15vh] px-4 sm:px-6 lg:px-8 ",
          props.className
        )
      }>
        {/* Softer Gradient */}
        {/*<div className="absolute inset-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-blue-500 opacity-10 -z-10"></div>*/}
        {/*<div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>*/}
        <div className="w-full mx-auto relative z-10 text-center md:grid md:items-center">
          <div className='flex flex-col w-full mx-auto items-center space-y-4'>

            <h2 className="flex flex-row text-lg font-semibold text-indigo-600 tracking-wider uppercase mb-2">
              Effortless LinkedIn Content Creation
            </h2>

            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="max-w-6xl text-5xl sm:text-6xl md:text-7xl font-extrabold tracking-tight text-foreground/90 leading-tight">
              Quickly Amplify <br className="hidden md:block" />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-indigo-500">
                Your LinkedIn Presence
              </span>
            </motion.h1>

            <p className="mt-8 max-w-xl mx-auto md:mx-0 text-xl text-muted-foreground">
              Create LinkedIn posts that sound like you, effortlessly, with just a few clicks.
            </p>

            <div className="mt-10 flex justify-center md:justify-start">
              <Button
                variant="default"
                className="px-8 py-3 text-base font-medium rounded-md bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => navigate('/account/signup')}
              >
                Get Started for Free
              </Button>
            </div>

          </div>
          {/*/!* Right Side Placeholder *!/*/}
          {/*<div className="hidden md:block">*/}
          {/*  /!* Add your visually interesting element here *!/*/}
          {/*</div>*/}
        </div>
      </section>
    </WavyBackground>
  );
};


function LandingScreen(props) {
  return (
    <div className='flex flex-col w-full'>
      <Hero />
      <HeroSubsection />
      <Features className='bg-muted/40' />
      <HowItWorks />
      <Pricing className='bg-muted/40' />
      <ContactUs />
    </div>
  );
}

export default LandingScreen;