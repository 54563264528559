import {CONFIG} from "src/commons/config";
import {UTILS} from "src/commons/utils";


export const AUTH_APIS = {
  register: (data, config={}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: '/api/v1/auth/signup/',
      method: 'POST',
      data: data,
      ...config
    })
  },

  authenticate: (data, config={}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: '/api/v1/auth/login/',
      method: 'POST',
      data: data,
      ...config
    })
  },

  socialConnect: (provider, data, config={}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/auth/socials/${provider}/`,
      method: 'POST',
      data: data,
      ...config
    })
  },

  socialConnectCallback: (provider, data, config={}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/auth/socials/${provider}/callback/`,
      method: 'POST',
      data: data,
      ...config
    })
  },

  sendOtp: (data, config={}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/auth/otp/`,
      method: 'POST',
      data: data,
      ...config
    })
  },

  verifyOtp: (data, config={}) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/auth/otp/`,
      method: 'PATCH',
      data: data,
      ...config
    })
  }
}