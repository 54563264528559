import {
  PLAN_DELETE_FAIL,
  PLAN_DELETE_REQUEST,
  PLAN_DELETE_SUCCESS,
  PLAN_DELETE_RESET,

  PLAN_LIST_FAIL,
  PLAN_LIST_REQUEST,
  PLAN_LIST_SUCCESS,
  PLAN_LIST_RESET,

  PLAN_RETRIEVE_FAIL,
  PLAN_RETRIEVE_REQUEST,
  PLAN_RETRIEVE_SUCCESS,
  PLAN_RETRIEVE_RESET,

  PLAN_UPDATE_FAIL,
  PLAN_UPDATE_REQUEST,
  PLAN_UPDATE_SUCCESS,
  PLAN_UPDATE_RESET
} from "src/store/plan/constants";


export const list = (state = {}, action) => {
  switch (action.type) {
    case PLAN_LIST_REQUEST:
      return { loading: true,};
    case PLAN_LIST_SUCCESS:
      return { loading: false, plans: action.payload };
    case PLAN_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PLAN_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const retrieve = (state = {}, action) => {
  switch (action.type) {
    case PLAN_RETRIEVE_REQUEST:
      return { loading: true,};
    case PLAN_RETRIEVE_SUCCESS:
      return { loading: false, plan: action.payload };
    case PLAN_RETRIEVE_FAIL:
      return { loading: false, error: action.payload };
    case PLAN_RETRIEVE_RESET:
      return {};
    default:
      return state;
  }
};

export const update = (state = {}, action) => {
  switch (action.type) {
    case PLAN_UPDATE_REQUEST:
      return { loading: true };
    case PLAN_UPDATE_SUCCESS:
      return { loading: false, success: true, plan: action.payload };
    case PLAN_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PLAN_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};


export const deletePlan = (state = {}, action) => {
  switch (action.type) {
    case PLAN_DELETE_REQUEST:
      return { loading: true };
    case PLAN_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PLAN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PLAN_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

