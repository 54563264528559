import React from 'react';
import {Label} from "src/components/ui/label";
import {Input as BaseInput} from "src/components/ui/input";
import {UTILS} from "src/commons/utils";
import {cn} from "src/lib/utils";

function Input(props) {
  return (
    <div className={cn("flex flex-col w-full gap-y-1 text-foreground", props.className)}>
      {
        !props.hidelabel ? (
          <Label className={cn(
            'flex flex-row text-[12px] gap-x-1 text-foreground',
            props.label?.className
          )}
                 htmlFor={UTILS.toTitleCase(props.label).toLowerCase()}>
            <span className='flex flex-col'>{UTILS.toTitleCase(props.label)}</span>
            {
              props.required && (
                <span className='flex flex-col text-destructive my-auto'>*</span>
              )
            }
          </Label>
        ) : (
          <div className='flex flex-row flex-1'></div>
        )
      }
      <BaseInput
        className={cn(
          'flex flex-row',
          props.input?.className
        )}
        type='text'
        name={UTILS.toTitleCase(props.label).toLowerCase()}
        id={`${UTILS.toTitleCase(props.label).toLowerCase()}`}
        placeholder='Input'
        {...props}
      />
    </div>
  );
}

export default Input;