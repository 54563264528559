import {UTILS} from "src/commons/utils";
import {CONFIG} from "src/commons/config";


export const CONTACT_APIS = {
    create: (data, config={}) => {
      return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/core/contact-us/`,
      method: 'POST',
      data: data,
      ...config
    })
  },
}