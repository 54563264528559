import {
  CONTACT_US_CREATE_FAIL,
  CONTACT_US_CREATE_REQUEST,
  CONTACT_US_CREATE_SUCCESS
} from "src/store/contact/constants";
import {CONTACT_APIS} from "src/apis/contactApis";


export const create = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_US_CREATE_REQUEST
    });

    let response = await CONTACT_APIS.create(data);
    response = response.data;

    if (response && response.error) {
      throw response;
    }

    dispatch({
      type: CONTACT_US_CREATE_SUCCESS,
      payload: response,
    });
  }
  catch (error) {
    dispatch({
      type: CONTACT_US_CREATE_FAIL,
      payload: error.response ? error.response.data : {
        error: true,
        message: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      }
    });
  }
};