import React, {useEffect} from 'react';
import {cn} from "src/lib/utils";
import {LoaderCircle} from "lucide-react";

function Loading(props) {
  return (
    <div className={
      cn('flex flex-col h-full w-full m-auto justify-center items-center', props.className)
    }>
      <LoaderCircle className={
        cn('h-4 w-4 animate-spin text-foreground m-auto', props.loader.className)
      } />
    </div>
  );
}

export default Loading;