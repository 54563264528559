import React from 'react';
import {Link} from "react-router-dom";

function TermsAndConditionsScreen() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-[12vh]">
      <h1 className="relative text-4xl font-bold text-center p-8 m-4">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-600 opacity-10 z-40 rounded-3xl"></div>
        Terms and Conditions
      </h1>

      <div className='flex flex-col gap-8 p-12'>
        <section className='flex flex-col'>
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p>
            Welcome to PostPropel ("we," "us," "our") (unregistered). These Terms and Conditions ("Terms") govern your use of our website, products, and services ("Services"). By using our Services, you ("User" or "you") agree to comply with these Terms. If you do not agree with these Terms, you may not use our Services.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">About Us</h2>
          <p>
            PostPropel is an unregistered business operating from India, offering content creation and growth acceleration services globally. We are working towards formal registration to enhance our legal standing and credibility. In the meantime, we strive to maintain high standards of professionalism and customer service.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Definitions</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <strong>"Services"</strong>: Refers to all products and features provided by PostPropel, including content creation tools and posting features.
            </li>
            <li className="mb-2">
              <strong>"Content"</strong>: Includes all text, images, and other materials created or managed through our Services.
            </li>
            <li>
              <strong>"User-Generated Content"</strong>: Refers to any content that you create and upload using our Services.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Use of Services</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <strong>Eligibility:</strong> By using our Services, you represent that you are at least 18 years old and legally capable of entering into binding contracts.
            </li>
            <li className="mb-2">
              <strong>Access:</strong> We grant you limited, non-exclusive, non-transferable access to use our Services. You agree not to reproduce, duplicate, copy, sell, or exploit any portion of the Services without our express written consent.
            </li>
            <li>
              <strong>Account Creation:</strong> To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account and for all activities that occur under it.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <strong>PostPropel IP:</strong> All intellectual property rights, including trademarks, copyrights, and proprietary content, remain the exclusive property of PostPropel. Unauthorized use of our intellectual property is prohibited.
            </li>
            <li>
              <strong>User Content:</strong> You retain ownership of your User-Generated Content. By using our Services, you grant PostPropel a non-exclusive, worldwide, royalty-free license to use, display, and distribute such content to operate and improve our Services.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">User Responsibilities</h2>
          <p>You agree not to:</p>
          <ol className="list-decimal list-inside ml-6">
            <li>Use our Services for unlawful purposes.</li>
            <li>Disrupt or interfere with the functionality of our Services.</li>
            <li>Provide false or misleading information when using our Services.</li>
            <li>Create, distribute, or post malicious content, including fake news or spam.</li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">AI-Specific Clauses</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <strong>Accuracy of AI:</strong> Our AI tools assist in content creation but do not guarantee accuracy or completeness. Review and verify AI-generated content before publishing.
            </li>
            <li className="mb-2">
              <strong>Misuse of AI:</strong> Do not use our AI tools to create or distribute harmful, misleading, or illegal content.
            </li>
            <li>
              <strong>Data Training:</strong> User data used for training our AI is anonymized. We take steps to ensure that data used for training is protected and does not identify individuals.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Pricing and Payments</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <strong>Pricing:</strong> Pricing for our Services is available on our website. We reserve the right to change pricing at any time.
            </li>
            <li>
              <strong>Payments:</strong> Payments must be made through accepted methods. By making a purchase, you agree to pay the associated fees.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Refund Policy</h2>
          <p>
            We offer a 7-day refund period from the date of purchase under the following conditions:
          </p>
          <ol className="list-decimal list-inside ml-6">
            <li>Refunds are available for Services not used or substantially delivered.</li>
            <li>To request a refund, contact us at <a href="mailto:support@postpropel.com" className="text-blue-500 hover:underline">support@postpropel.com</a> with your order details and reason.</li>
            <li>Refunds will be processed to the original payment method within a reasonable time after approval.</li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Subscription Services</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <strong>Renewal:</strong> Subscription plans renew automatically at the end of the term unless canceled beforehand.
            </li>
            <li>
              <strong>Cancellation:</strong> You may cancel your subscription anytime by following the instructions on our website. Cancellations will take effect at the end of the current billing cycle.
            </li>
          </ol>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Liability Limitation</h2>
          <p>
            PostPropel aims to provide high-quality Services but does not guarantee uninterrupted or error-free performance. To the fullest extent permitted by law, PostPropel disclaims liability for direct, indirect, or consequential damages, including those arising from inaccuracies in AI-generated content or service disruptions.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Dispute Resolution</h2>
          <p>
            Disputes arising from these Terms or your use of our Services will be resolved through arbitration. The arbitration will be conducted in English and held in a neutral location agreed upon by both parties.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the laws of India. For disputes not resolved through arbitration, the jurisdiction of the courts in India will apply, unless a neutral location is otherwise agreed upon.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Global Service Provision</h2>
          <p>
            PostPropel operates globally. Users outside India must comply with local laws in their jurisdictions.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Termination Clause</h2>
          <p>
            We may terminate your account or access to our Services if you breach these Terms or engage in harmful conduct. Termination is effective immediately upon notice.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">User-Generated Content (UGC)</h2>
          <p>
            You are responsible for content created and posted using our platform. Ensure your content does not infringe copyrights, violate laws, or include hate speech or illegal activities.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Third-Party Links/Integrations</h2>
          <p>
            Our Services may include links to or integrations with third-party platforms such as LinkedIn. We are not responsible for their content or actions and disclaim liability arising from interactions with these third parties.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Force Majeure</h2>
          <p>
            PostPropel is not liable for failures to perform obligations due to causes beyond our control, including natural disasters, pandemics, or other unforeseeable events.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Accessibility</h2>
          <p>
            We are committed to making our Services accessible to users with disabilities. If you encounter issues, please contact us for assistance.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
          <p>
            We may modify these Terms at any time. Changes will be posted on this page, and your continued use of our Services constitutes acceptance of the revised Terms.
          </p>
        </section>

        <section className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
          <p>
            Our <Link to='/policies/privacy' className="text-blue-500 hover:underline">Privacy Policy</Link> has more details regarding data and privacy.
          </p>
        </section>

        <section className="flex flex-col mt-4">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p>
            For questions about these Terms or our Services, contact us at:
          </p>
          <p className="font-bold">PostPropel</p>
          <p>Email: <a href="mailto:support@postpropel.com" className="text-blue-500 hover:underline">support@postpropel.com</a></p>
        </section>
      </div>


    </div>
  );
}


export default TermsAndConditionsScreen;