// constants/constants.js
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_RESET = 'LOGIN_RESET';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNUP_RESET = 'SIGNUP_RESET';

export const SOCIAL_CONNECT_REQUEST = 'SOCIAL_CONNECT_REQUEST';
export const SOCIAL_CONNECT_SUCCESS = 'SOCIAL_CONNECT_SUCCESS';
export const SOCIAL_CONNECT_FAIL = 'SOCIAL_CONNECT_FAIL';
export const SOCIAL_CONNECT_RESET = 'SOCIAL_CONNECT_RESET';

export const SOCIAL_CALLBACK_REQUEST = 'SOCIAL_CALLBACK_REQUEST';
export const SOCIAL_CALLBACK_SUCCESS = 'SOCIAL_CALLBACK_SUCCESS';
export const SOCIAL_CALLBACK_FAIL = 'SOCIAL_CALLBACK_FAIL';
export const SOCIAL_CALLBACK_RESET = 'SOCIAL_CALLBACK_RESET';

export const OTP_SEND_REQUEST = 'OTP_SEND_REQUEST';
export const OTP_SEND_SUCCESS = 'OTP_SEND_SUCCESS';
export const OTP_SEND_FAIL = 'OTP_SEND_FAIL';
export const OTP_SEND_RESET = 'OTP_SEND_RESET';

export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAIL = 'OTP_VERIFY_FAIL';
export const OTP_VERIFY_RESET = 'OTP_VERIFY_RESET';
