import React from 'react';

import {
  ThumbsUp,
  Heart,
  Dot,
  Triangle,
  MessageSquareText,
  Repeat2,
  Send,
  Earth,
  Ellipsis,
  X,
} from "lucide-react";

import {DATA} from "src/commons/data";
import {Link} from "react-router-dom";


function PostHeader(props) {
  const { data } = props;

  return (
    <div className="flex justify-between mb-4">
      <div className="flex items-center gap-x-2 text-start">
        {/* Add Reposted label if needed */}
        <img
          src={data.picture || "/media/images/illustrations/profile-pic-2-sm.webp"}
          alt="Profile"
          className="w-10 h-10 rounded-full"
        />
        <div className='flex flex-col'>
          <h3 className="font-semibold text-sm">{data.name}</h3>
          <div className="text-xs text-foreground/50">
            {data.bio}
          </div>

          <div className="flex flex-row text-xs text-foreground/50 gap-x-0 justify-start">
            <div className='flex flex-row'>Now</div>
            <Dot className='flex flex-col h-[15px] my-auto' />
            <div className='flex flex-row'>Edited</div>
            <Dot className='flex flex-col h-[15px] my-auto' />
            <Earth className='flex flex-col h-3 w-3 my-auto' />
          </div>
        </div>

      </div>
      <div className="flex items-start gap-x-2">
        <button>
          <Ellipsis className="w-4 h-4 text-foreground/50" />
        </button>
        <button>
          <X className="w-4 h-4 text-foreground/50" />
        </button>
      </div>
    </div>
  );
};

// PostContent.jsx
function PostContent(props) {
  return (
    <div className="text-[14px] items-start text-start justify-start mb-4 py-2">
      <p>🚀 <span>Elevate Your LinkedIn Presence Effortlessly!</span> 🚀</p>
      <br/>
      <p>Struggling to keep up with LinkedIn content? Let rePOST handle it! Our AI-powered tool makes creating and managing posts a breeze:</p>
      <br/>
      <ul>
        <li>* Generate Content with AI: Input a draft or outline and get tailored posts in minutes.</li>
        <li>* Seamless LinkedIn Integration: Connect and post directly from our platform.</li>
        <li>* Personalized Style: Upload your samples for content that matches your voice.</li>
      </ul>
      <br/>
      <p>Get started today and boost your LinkedIn presence with ease!</p>
      <br/>
      <p><Link to="/account/signup" className="text-blue-700 underline font-bold">👉 Try Now</Link></p>
      <br/>
      <p className='text-blue-700'>#LinkedIn #ContentCreation #AI #Marketing</p>
    </div>
  );
};

// PostFooter.jsx
function PostFooter(props) {
  const { data } = DATA;

  return (
    <div className="flex flex-col w-full gap-y-1">
      <div className="flex flex-row items-center gap-x-4 text-foreground/50 text-xs justify-between">
        <div className='flex flex-row gap-x-1'>
          <div className='flex flex-row space-x-[-5px]'>
            <ThumbsUp className="w-5 h-5 scale-x-[-1] rounded-full p-[2px] border-[1px] border-white bg-[#508DE2] text-[#4070b4]" fill='#cadcf6' />
            <Heart className="w-5 h-5 scale-x-[-1] rounded-full p-[2px] border-[1px] border-white bg-[#D17655] text-[#a75e44]" fill='#f1d5cc' />
          </div>
          <span className='flex flex-col my-auto'>423</span>
        </div>
        <div className='flex flex-row justify-end'>
          <div className='flex flex-col my-auto'>203 comments</div>
          <Dot className='flex flex-col h-[15px] my-auto' />
          <div className='flex flex-col my-auto'>13 reposts</div>
        </div>
      </div>

      <div className="flex items-center border-t pt-4 gap-x-6">
        <div className="flex items-center gap-x-1">
          <img
            src={data?.picture || "/media/images/illustrations/profile-pic-2-sm.webp"}
            alt="Profile"
            className="w-6 h-6 rounded-full"
          />
          <Triangle className='h-[6px] w-[8px] rotate-180' fill />
        </div>
        <div className="flex-grow flex items-center justify-between text-foreground/50">
          <button className="flex items-center gap-x-1">
            <ThumbsUp className="w-4 h-4 scale-x-[-1]" />
            Like
          </button>
          <button className="flex items-center gap-x-1">
            <MessageSquareText className="w-4 h-4" />
            Comment
          </button>
          <button className="flex items-center gap-x-1">
            <Repeat2 className="w-4 h-4" />
            Repost
          </button>
          <button className="flex items-center gap-x-1">
            <Send className="w-4 h-4" />
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

function LinkedinPost(props) {
  const { social } = DATA;

  return (
    <div className='flex flex-row w-full h-full m-auto py-12 px-2'>
      <div className="flex flex-col w-full border rounded-md p-4 shadow-md m-auto">
        <PostHeader data={social.linkedin} />
        <PostContent />
        <PostFooter data={social.linkedin} />
      </div>
    </div>
  );
}

export default LinkedinPost;