import React from 'react';
import {
  LoaderCircle
} from "lucide-react";
import {Button as BaseButton} from 'src/components/ui/button';

function Button(props) {
  return (
    <BaseButton
      className={props.className}
      disabled={props.loading || props.disabled}
      {...props}
    >
      {props.loading ? (
        <LoaderCircle className='h-4 w-4 animate-spin' />
      ) : props.children
      }
    </BaseButton>
  );
}

export default Button;