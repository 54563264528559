export const PLAN_LIST_REQUEST = 'PLAN_LIST_REQUEST';
export const PLAN_LIST_SUCCESS = 'PLAN_LIST_SUCCESS';
export const PLAN_LIST_FAIL = 'PLAN_LIST_FAIL';
export const PLAN_LIST_RESET = 'PLAN_LIST_RESET';

export const PLAN_RETRIEVE_REQUEST = 'PLAN_RETRIEVE_REQUEST';
export const PLAN_RETRIEVE_SUCCESS = 'PLAN_RETRIEVE_SUCCESS';
export const PLAN_RETRIEVE_FAIL = 'PLAN_RETRIEVE_FAIL';
export const PLAN_RETRIEVE_RESET = 'PLAN_RETRIEVE_RESET';

export const PLAN_UPDATE_REQUEST = 'PLAN_UPDATE_REQUEST';
export const PLAN_UPDATE_SUCCESS = 'PLAN_UPDATE_SUCCESS';
export const PLAN_UPDATE_FAIL = 'PLAN_UPDATE_FAIL';
export const PLAN_UPDATE_RESET = 'PLAN_UPDATE_RESET';


export const PLAN_DELETE_REQUEST = 'PLAN_DELETE_REQUEST';
export const PLAN_DELETE_SUCCESS = 'PLAN_DELETE_SUCCESS';
export const PLAN_DELETE_FAIL = 'PLAN_DELETE_FAIL';
export const PLAN_DELETE_RESET = 'PLAN_DELETE_RESET';