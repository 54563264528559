import logo from './logo.svg';
import './App.css';
import Layout from "src/layout/Layout";
import Header from "src/layout/Header";
import Content from "src/layout/Content";
import LoginScreen from "src/screens/LoginScreen";
import Footer from "src/layout/Footer";

import Router from "src/routers/Router";
import {useEffect} from "react";
import {UTILS} from "src/commons/utils";
import {CONFIG} from "src/commons/config";

function App() {

  useEffect(() => {
    const token = UTILS.getToken(false);
    if(token && !UTILS.isNull(token) && token.length > 0 && typeof token === 'string') {
      window.location.replace(CONFIG.APP_FRONTEND_URL);
    }
  }, []);

  return (
    <Layout className='flex flex-col'>
      <Header className='flex flex-row' />

      <Content className='flex flex-row'>
        <Router />
      </Content>

      <Footer className='flex flex-row' />
    </Layout>
  );
}

export default App;
