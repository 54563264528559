import React, {useEffect, useState} from 'react';
import {Form, Link, useNavigate, useSearchParams} from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "src/components/ui/card";
import Input from "src/components/Input";
import Button from "src/components/Button";
import {cn} from "src/lib/utils";
import {toast, useToast} from "src/components/ui/use-toast";
import {CircleCheck, TriangleAlert} from "lucide-react";
import {UTILS} from "src/commons/utils";
import {useDispatch, useSelector} from "react-redux";
import {login, signup, socialConnect} from "src/store/auth/actions";
import {CONFIG} from "src/commons/config";
import OtpCard from "src/components/OtpCard";


function SignupForm(props) {
  const { toast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [invalidData, setInvalidData] = useState();

  const { loading: emailSignupLoading, auth: emailSignupAuth, error: emailSignupError } = useSelector(state => state.signup);
  const { loading: socialConnectLoading, redirect, error: socialConnectError } = useSelector(state => state.socialConnect);

  useEffect(() => {
    let redirectTimeout;

    if(emailSignupError || socialConnectError) {
      toast({
        title: (
          <div className='flex flex-row gap-x-1 justify-start'>
            <div className='flex flex-col'>
              <TriangleAlert className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Uh Oh! Cannot Authenticate!
            </div>
          </div>
        ),
        description: emailSignupError ? emailSignupError.message : socialConnectError.message,
        variant: 'destructive',
      });
    } else if(emailSignupAuth) {
      toast({
        title: (
          <div className='flex flex-row gap-x-1 justify-start'>
            <div className='flex flex-col'>
              <CircleCheck className=''/>
            </div>
            <div className='flex flex-col my-auto'>
              Email Verification
            </div>
          </div>
        ),
        description: 'Please enter the code sent to your email.',
      });

      navigate('/account/signup?step=verify-otp');
    } else if(redirect) {
      window.location.replace(redirect);
    }

    return () => {
      clearTimeout(redirectTimeout);
    }
  }, [emailSignupError, socialConnectError, emailSignupAuth, redirect]);

  const onEmailSignupHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    if(data?.confirm_password && data?.password !== data?.confirm_password) {
      setInvalidData('Passwords do not match!');

      toast({
        title: (
          <div className='flex flex-row gap-x-1 justify-start'>
            <div className='flex flex-col'>
              <TriangleAlert className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Uh Oh! Passwords don't match!
            </div>
          </div>
        ),
        description: 'Passwords do not match!',
        variant: 'destructive',
      });

      return;
    } else if(!UTILS.validatePasswordStrength(data.password)) {
      setInvalidData((
        <div className='flex flex-col'>
          <b>Strong Password must contain:</b>
          <ul className='text-[12px]'>
            <li>Minimum 8 characters.</li>
            <li>Contains lowercase letters.</li>
            <li>Contains uppercase letters.</li>
            <li>Contains number.</li>
            <li>[optional] Contains special characters.</li>
          </ul>
        </div>
      ));

      toast({
        title: (
          <div className='flex flex-row gap-x-1 justify-start'>
            <div className='flex flex-col'>
              <TriangleAlert className='' />
            </div>
            <div className='flex flex-col my-auto'>
              Uh Oh! Weak Password!
            </div>
          </div>
        ),
        description: 'Passwords is not strong enough! Make sure it follows the guidelines.',
        variant: 'destructive',
      });
      return;
    } else {
      setInvalidData(null);
    }

    dispatch(signup(data));
  }

  const onSocialConnectHandler = (e, provider) => {
    e.preventDefault();

    const provider_id = provider.includes('/') ? provider.split('/').slice(-1)[0] : provider;
    const data = {
      provider: provider_id,
      process: 'login',
      callback_url: `${CONFIG.AUTH_FRONTEND_URL}/accounts/providers/${provider_id}/callback`
    }

    dispatch(socialConnect(provider, data));
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Sign Up</CardTitle>
        <CardDescription>
          Enter your details below to create a new account
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form id='signupForm' className="grid gap-4" onSubmit={onEmailSignupHandler}>
          <div className="grid gap-2">
            <Input
              id="email"
              type="email"
              label="Email"
              placeholder="m@example.com"
              required
            />
          </div>
          <div className="grid gap-2">
            <Input
              id="password"
              type="password"
              label="Password"
              placeholder="********"
            required />
          </div>
          <div className="grid gap-2">
            <Input
              id="confirm_password"
              type="password"
              label="Confirm Password"
              placeholder="********"
              required />
          </div>

          {
            invalidData && (
              <div className='flex flex-row w-full py-4'>
                <p className="text-[14px] text-!red-600">
                  {invalidData}
                </p>
              </div>
            )
          }

          <Button
            form='signupForm'
            type="submit"
            className="w-full"
            disabled={emailSignupLoading || socialConnectLoading || redirect}
            loading={emailSignupLoading}
          >
            Signup
          </Button>
          <Button
            type='button'
            variant="outline"
            className="w-full"
            disabled={emailSignupLoading || socialConnectLoading || redirect}
            loading={socialConnectLoading || redirect}
            onClick={(e) => onSocialConnectHandler(e, CONFIG.SOCIAL_CONNECT_PROVIDERS.LINKEDIN)}
          >
            Login with LinkedIn
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Already have an account?{" "}
          <Link to="/account/login" className="underline">
            Login
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}

function SignupScreen(props) {
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {auth} = useSelector(state => state.signup);

  useEffect(() => {
    if(searchParams.get('step') === 'verify-otp') {
      if(!auth) navigate('/account/signup');
    }
  }, [searchParams, auth]);

  return (
    <div className={(
      cn('h-full w-full m-auto sm:py-[21vh] py-[11vh] sm:px-0 px-4 bg-muted/40')
    )}>
      {
        (searchParams.get('step') === 'verify-otp' && auth) ? (
          <OtpCard context={auth} redirect={CONFIG.APP_FRONTEND_URL} />
        ) : (
          <SignupForm />
        )
      }
    </div>
  );
}

export default SignupScreen;